import React, { useRef } from 'react';
import './loading.scss'

export default function Loading() {
  return (
    <div className="loading-icon">
      <div className="centered">
        <span className="circle circle-1"></span>
        <span className="circle circle-2"></span>
        <span className="circle circle-3"></span>
        <span className="circle circle-4"></span>
        <span className="circle circle-5"></span>
        <span className="circle circle-6"></span>
        <span className="circle circle-7"></span>
        <span className="circle circle-8"></span>
      </div>
    </div>


  )
};
