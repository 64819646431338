import React from 'react';
import './hero.scss'

export default function Hero(props) {
  var currentTheme = 1;
  var logo = "images/logo.png";
  var chestBg = "images/01_hero/hero-background-4.png";
  var astroImg = "images/01_hero/hero-character-3.png";
  var headingImg = "images/01_hero/heading.svg";
  if (props.theme && props.theme === 2) {
    currentTheme = 2; 
    chestBg = "images/01_hero/hero-background-3.png"
    astroImg = "images/01_hero/hero-character-4.png"
    headingImg = "images/01_hero/heading-2.svg";
  }
  const sectionBgStyle = { backgroundImage: 'url(' + chestBg + ')' };
  const corpColorBgStyle = { backgroundColor: props.primaryColor };
  const logoStyle = { backgroundImage: 'url(' + logo + ')' };
  return (
    <section id="hero-section" className={['hero section section-bg-1 text-center visible', props.currentSlide === 1 ? 'active' : ''].join(' ')} style={sectionBgStyle}>
      <div className="grid-container hero-container">
        <div className="grid-x align-center align-middle full-height pt60 pb80">
          <div className="cell">
            <div className="section-container section-gap-2">

              <div className="section-description hero-heading">
                <img className="hero-heading-img" src={headingImg} alt="Join the Trailblazer Challenge" />
              </div>
              <div className={["section-description", currentTheme === 2 ? '' : ''].join(' ')}>
                <span className="bold margin-bottom-15">What is a Trailblazer?</span><br />A pioneer. An innovator. A lifelong learner. A mover and a shaker.
                <br /><br /> Join us on a trailblazing journey to skill up for the future, learn from luminaries and experts,
                <br /> and connect to a vibrant community of your peers.
              </div>
              <div className="hero-button">
                <button style={corpColorBgStyle} type="button" onClick={() => props.goToSlide(2)}>Let&rsquo;s go!</button>
                {/* <div className="dates">February 14 - April 14, 2022</div> */}
                {/* <div className="company-logo" style={logoStyle} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-character">
        <img src={astroImg} alt="Astro" width="330" height="400" />
      </div>
    </section>
  )
};
