import './sfplus_page.scss'
import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import SfPlusCard from './sfplus_card';
import smBaloon, { ReactComponent as SmallBaloon } from './images/small_baloon2.svg'
import lgBaloon, { ReactComponent as LargeBaloon } from './images/large_baloon2.svg'
import LegalFooter from '../legal_footer/legal_footer';
import { useSpring, animated } from 'react-spring'
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 50}px,${y / 50 + 550}px,0)`
const trans2 = (x, y) => `translate3d(${-x / 30}px,${y / 30 + 550}px,0)`

export default function RewardsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);
  var currentTheme = 1;
  var heroTopBg = "images/sfplus_page/inspired-top.png";
  var heroBotBg = "images/sfplus_page/inspired-bottom.png";
  if (props.settings.theme && props.settings.theme === 2) {
    currentTheme = 2;
    heroTopBg = "images/sfplus_page/inspired-top-2.png"
    heroBotBg = "images/sfplus_page/inspired-bottom-2.png"
  }
  const sectionTopBgStyle = { backgroundImage: 'url(' + heroTopBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + heroBotBg + ')' };
  const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 50, tension: 550, friction: 140 } }));

  ReactGA.event({
    category: 'Team',
    action: 'page visited'
  });
  return (
    <>
      <Nav settings={props.settings} />
      <section id="sf_plus-page" className={["material section section-gap-1 section-bg-gi full-height text-center", currentTheme === 1 ? 'darkbg' : 'white-bg'].join(' ')} >
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div className="background-image-bottom" style={sectionBotBgStyle}></div>
        <div className="container" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <animated.div className={["small-baloon", currentTheme === 1 ? "" : "hidden"].join(' ')} style={{ transform: settings.xy.interpolate(trans1) }}><SmallBaloon /></animated.div>
          <animated.div className={["large-baloon", currentTheme === 1 ? "" : "hidden"].join(' ')} style={{ transform: settings.xy.interpolate(trans2) }}><LargeBaloon /></animated.div>
          <div className="grid-container relative">
            <div className="grid-x">
              <div className="cell">
                <div className="section-container dark">
                  <h1 className={["section-title", currentTheme === 1 ? "white-text" : ""]}>Get inspired to blaze your trail.</h1>
                  {/* <h2 className="section-subtitle">Explore relevant resources to help you succeed on your Trailhead Journey.</h2> */}
                  <div className={["section-subtitle", currentTheme === 1 ? "white-text" : ""]}>
                    Tune into bursts of inspiration <br />
                    from industry experts, thought leaders, and luminaries<br />
                    — watch it all on-demand.</div>
                  <div className="material-card--container">
                    <div className="grid-container">
                      <Equalizer property="height" className="grid-x align-center align-top">
                        {Object.keys(props.settings.resources).map((t) => {
                          if (props.settings.resources[t].doc_type === "sfplus")
                            return <SfPlusCard
                              key={uuid()}
                              cardTitle={t}
                              name={props.settings.resources[t].name}
                              subtitle={props.settings.resources[t].subtitle}
                              permalink={props.settings.resources[t].permalink}
                              image={props.settings.resources[t].image_url}
                              trackEvent={props.trackEvent}
                            />
                        })}
                      </Equalizer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
      </section>
    </>
  )
};
