import React from 'react';
import './trails.scss';
import Trail from './trail';
import uuid from 'react-uuid'
import Loading from "../loading/loading_icon";

export default function TrailList(props) {
  if (props.trailsLoading === false) {
    return (
      <>
        <div className="trails-card--middle" >
          {/* <h3 className="trail--title">{props.currentChest.title}</h3> */}

          <div className="trails-card--block">
            <div className="learn-buttons center">
              <a onClick={props.trackEvent('Clicked Trailmix: '+props.currentChest.title)} href={props.currentChest.permalink} target="_blank" rel="noopener noreferrer" className="main-button button learn-button">
                Learn these skills
              </a>
            </div>
            {Object.keys(props.trailData).map((t) => (
              <Trail
                key={uuid()}
                trailData={props.trailData[t]}
                trailIndex={t}
                trackEvent={props.trackEvent}
              />
            ))}
          </div>
        </div>
      </>

    )
  } else {
    return (
      <>
        <div className="trails-card--middle relative" >
          <Loading />
        </div>
      </>
    )
  }
};
