export default {
	members: [
	  {
	    name: "Vanessa Müller",
	    company: "Robert Bosch GmbH: BSH, BT, DC, IOX, PT",
	    linkedin: "vmueller@salesforce.com",
	    title: "Strategic Account Manager",
	    photo: "https://trailheadcms.s3.amazonaws.com/logos/bosch/vanessamuller.jpg"
	  },
	  {
	      name: "Stephanie Peiker",
	      company: "Robert Bosch GmbH: BBM, BSO, TT",
	      linkedin: "speiker@salesforce.com",
	      title: "Strategic Account Manager",
	      photo: "https://trailheadcms.s3.amazonaws.com/logos/bosch/stephaniepeiker.jpeg"
	  },
	  {
	    name: "Lea Gerner",
	    company: "Robert Bosch GmbH",
	    linkedin: "lgerner@salesforce.com",
	    title: "Account Executive",
	    photo: "https://trailheadcms.s3.amazonaws.com/logos/bosch/leagerner.jpeg"
	  },
	  {
	    name: "Dagmar Glunk",
	    company: "Robert Bosch GmbH",
	    linkedin: "dagmar.glunk@de.bosch.com",
	    title: "Digital CRM Salesforce",
	    photo: "https://trailheadcms.s3.amazonaws.com/logos/bosch/dagmar.png"
	  },
	  
	],
	resources: [
	  {
	    name: "Getting Started",
	    subtitle: 'Subtitle',
	    permalink: "https://trailhead.salesforce.com/users/nankam/trailmixes/get-started-with-trailhead",
	    image: "/images/sfplus_page/img1.png"
	  },
	  {
	    name: "Trailhead Help",
	    subtitle: 'Subtitle',
	    permalink: "https://trailhead.salesforce.com/help",
	    image: "/images/sfplus_page/img3.png"
	  },
	  {
	    name: "Trailblazer Community",
	    subtitle: 'Subtitle',
	    permalink: "https://trailblazers.salesforce.com/",
	    image: "/images/sfplus_page/img2.png"
	  }
	],
      
	com_resources: [
	  {
	    name: "Getting Started",
	    subtitle: 'Subtitle',
	    permalink: "https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000001sArCAI?tab=discussion",
	    image: "/images/community_page/img2.png"
	  },
	  {
	    name: "Trailhead Help",
	    subtitle: 'Subtitle',
	    permalink: "https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000009NekCAE?tab=discussion",
	    image: "/images/community_page/img3.png"
	  },
	  {
	    name: "Trailblazer Community",
	    subtitle: 'Subtitle',
	    permalink: "https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000001omnCAA?tab=discussion",
	    image: "/images/community_page/img1.png"
	  }
	]
      }