export default {
  scout: {
    header: "Scout",
    image: "/images/rewards_page/rank-scout.png",
    badges: "0",
    points: "0"
  },
  hiker: {
    header: "Hiker",
    image: "/images/rewards_page/rank-hiker.png",
    badges: "1",
    points: "200"
  },
  explorer: {
    header: "Explorer",
    image: "/images/rewards_page/rank-explorer.png",
    badges: "5",
    points: "3,000"
  },
  adventurer: {
    header: "Adventurer",
    image: "/images/rewards_page/rank-adventurer.png",
    badges: "10",
    points: "9,000"
  },
  mountaineer: {
    header: "Mountaineer",
    image: "/images/rewards_page/rank-mountaineer.png",
    badges: "25",
    points: "18,000"
  },
  expeditioner: {
    header: "Expeditioner",
    image: "/images/rewards_page/rank-expeditioner.png",
    badges: "50",
    points: "35,000"
  },
  ranger: {
    header: "Ranger",
    image: "/images/rewards_page/rank-ranger.png",
    badges: "100",
    points: "50,000"
  }
}
