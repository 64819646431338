import React from 'react';
import './chests.scss';
// import './chests_page.scss';
import ChestList from './chest_list.js'
export default function Chests(props) {

  // const corpColorStyle = { color: props.primaryColor };

  return (
    <section id="chests-section" className={[props.currentSlide > 1 ? 'visible' : 'hidden', props.currentSlide === 3 ? 'active' : '', 'chests section section-bg-2 text-center'].join(' ')}>
      <div className="grid-container fluid">
        <div className="grid-x align-center align-top full-height">
          <div className="cell">
            <div className="section-container section-gap-2">
              <div className="grid-container relative z-index-1">
                <div className="grid-x align-center">
                  <div className="cell small-12 medium-7 large-8 chest-cell" >
                    <div className="section-tag">01/02</div>
                    <h2 className="section-title">Which skills would <em>you</em> like to unlock?</h2>
                    <h3 className="section-subtitle chests-section-subtitle text-glow">Explore skills that align with your role.<br/> Challenge yourself outside of your areas of expertise.</h3>
                  </div>
                </div>
              </div>
              <ChestList
                chests={props.chests}
                chestsLoading={props.chestsLoading}
                currentChest={props.currentChest}
                setChest={props.setChest}
                settings={props.settings}
              >
              </ChestList>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};
