import React from 'react';
import {ReactComponent as ChestImg} from './chest.svg'
// let chestCanOpen = true
export default function Chest(props){
  var timeOut = 2000;
  var theme = 1;
  if (props.settings && props.settings.theme && props.settings.theme === 2){
    theme = 2;
    timeOut = 1;
  }
  var chestImgPath = ""
  if (props.chestData.image) {
    chestImgPath = props.chestData.image
  }
  const chestImg = { backgroundImage: 'url(' + chestImgPath + ')' };
  function openChest(e) {
      props.chestData.isOpened = true;
      e.target.parentElement.classList.add("open");
      setTimeout(function() {
        props.setChest(props.chestIndex)
      }, timeOut);
    // };
  }
  if (theme === 2){
    return(
      <>
    <div className="cell small-6 medium-4 large-4 xlarge-3 xxlarge-3 xxxlarge-3">
    <div className="mixcard" onClick={(e) => openChest(e) }>
      <div className={["mixcard__image__wrap", props.chestData.theme && props.chestData.theme !== '' ? props.chestData.theme : 'no-theme'].join(' ')}>
        <div className={["checkmark", props.chestData.isOpened ? '' : 'hidden'].join(' ')}></div>
        <div className="mixcard__image" style={chestImg} alt={props.chestData.title} />
      </div>
      <h4 className="mixcard__title">
      {props.chestData.title}
      </h4>
        <div className="mixcard__description">{props.chestData.subtitle}</div>
      </div>
    </div>
</>
    )

  } else{
  return(
    <div className="cell small-6 medium-4 large-4 xlarge-3 xxlarge-3 xxxlarge-3">
      <div className="chest">
        <div className={props.chestData.isOpened ? "chest__image opened" : "chest__image"}>
          <div className="chest__clickzone" onClick={(e) => openChest(e) }></div>
          <ChestImg/>
        </div>
        <h4 className="chest__title">{props.chestData.title}</h4>
        <div className="chest__description">{props.chestData.subtitle}</div>
      </div>
    </div>
  )
  }
};
