import React from 'react';
export default function Badge(props) {
  return (
    <div className="cell small-12 medium-6 xlarge-4 xxlarge-3">
      <div className="rewards-card--item">
        <div className="rewards-card--image">
          <img src={props.image} alt={props.header} width="260" height="260" />
        </div>
        <div className="rewards-card--body">
          <h3 className="rewards-card--title">{props.header}</h3>
          <div className="rewards-card--info">
            <ul>
              <li><span>Badges:</span> <strong>{props.badges}</strong></li>
              <li><span>Points:</span> <strong>{props.points}</strong></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
