//#region |********\  Imports  ######################################
import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Nav from './nav.js';
import Hero from './01_hero/hero';
import Chests from './04_chests/chests';
import Trails from './06_trails/trails';
import Congrats from './07_congrats/congrats';
import LegalFooter from './legal_footer/legal_footer.js';
//#endregion |********///////////////////////////////////////////////////
//#region |********\  QUEST   #######################################
export default function Quest(props) {
  //# ||****\ VARS \\\\\\\\\\\\\\\\\\\\\\\\\\\\\
  //# |||**\ hooks -------
  const [currentSlide, setCurrentSlide] = useState(1);
  const [currentOpenedChests, setCurrentOpenedChests] = useState(0);
  const [currentChest, setCurrentChest] = useState(0);
  const [chestsLoading, setChestsLoading] = useState(true);
  const [trailsLoading, setTrailsLoading] = useState(true);
  useEffect(() => {
    if (currentSlide === 0) {
    goToSlide(1);
    }
    return () => {};
  });
  let legal_footer = <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />;
  let nav = <Nav
    settings={props.settings}
    nextSlide={incrementSlide}
    currentSlide={currentSlide}
    goToSlide={goToSlide}
  />;

  let hero = <Hero
    theme={props.settings.theme}
    logo={props.settings.logo_url}
    heroText={props.settings.hero_text}
    nextSlide={incrementSlide}
    goToSlide={goToSlide}
    currentSlide={currentSlide}
  />;

  let chests = <Chests
    chests={props.settings.chests}
    settings={props.settings}
    setSettings={props.setSettings}
    currentChest={props.settings.chests[currentChest]}
    setChest={setChest}
    currentSlide={currentSlide}
    chestsLoading={chestsLoading}
  />;
  let trails = <Trails
    primaryColor={props.settings.primary_color}
    trailsLoading={trailsLoading}
    currentSlide={currentSlide}
    trailData={props.settings.chests[currentChest].trails}
    currentChest={props.settings.chests[currentChest]}
    nextSlide={incrementSlide}
    goToSlide={goToSlide}
    setChestsLoading={setChestsLoading}
    trackEvent={props.trackEvent}
  />;
  let congrats = <Congrats
    primaryColor={props.settings.primary_color}
    settings={props.settings}
    chestTitle={props.settings.chests[currentChest].title}
    currentSlide={currentSlide}
    goToSlide={goToSlide}
  />;


  //# |||**/--------------
  //# ||****/////////////////////////////////////
  //# ||****\ FUNCTIONS  \\\\\\\\\\\\\\\\\\\\\\\

  function setChest(i) {
    var tempCompany = props.settings;
    tempCompany.chests[i].isOpened = true;
    props.setSettings(tempCompany);
    setCurrentChest(i);
    props.trackEvent('Picked chest: ' + tempCompany.chests[i].title);
    if (currentOpenedChests < 1) {
      setCurrentOpenedChests(currentOpenedChests + 1);
      incrementSlide();
    } else {
      setTrailsLoading(true);
      setCurrentSlide(3);
      scroll.scrollToBottom();
      setTimeout(function () {
        setTrailsLoading(false);
      }, 1000);
    }
  }

  function incrementSlide() {
    props.trackEvent('Went to slide ' + currentSlide);
    if (currentSlide === 1) {
      scroll.scrollToBottom();
      setTimeout(function () {
        setChestsLoading(false);
      }, 500);
    } else if (currentSlide === 2) {
      scroll.scrollToBottom();
      setTimeout(function () {
        setTrailsLoading(false);
      }, 500);

    } else {
      scroll.scrollToBottom();
    }
    setCurrentSlide(currentSlide + 1);
    props.setPasswordEntered(true);
  }
  function goToSlide(e) {
    setChestsLoading(true);
    setTrailsLoading(true);
    if (e === 1) {
      scroll.scrollToBottom();
      setTimeout(function () {
        // setChestsLoading(false);
        // setTrailsLoading(false);
      }, 500);
    } else if (e === 2) {
      scroll.scrollToBottom();
      setTimeout(function () {
        setChestsLoading(false);
        setTrailsLoading(false);
      }, 1000);

    } else {
      setTimeout(function () {
        scroll.scrollToBottom();
      }, 0);
    }
    setCurrentSlide(e);

  }

  //# ||****////////////////////////////////
  //# ||****\ RENDER \\\\\\\\\\\\\\\\\\\\\\\\\\\
  return (
    <div className="quest">
      {nav}
      {hero}
      {chests}
      {trails} 
      {congrats}
      {legal_footer}
    </div>
  );
  //# ||****///////////////////////////////////////////////////
}
//#endregion |********/#################################################
//#region |********\  Hook Functions (useFormInput)#####################
function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function handleChange(e) {
    setValue(e.target.value)
  }
  return {
    value,
    onChange: handleChange
  };
}
//#endregion |********///////////////////////////////////////////////////