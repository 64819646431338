import React from 'react';
export default function Trail(props) {
  return (
    <div key={props.trailData.name} className="trails-card--item">
      <div className="trails-card--image">
        <div className="flex-auto">
        { props.trailData.image_url.trim() !== '' ?  <img src={props.trailData.image_url} alt="Salesforce" width="100" height="100" /> : <div/>}
        </div>
      </div>
      <div className="flex-auto">
        <a onClick={props.trackEvent('Clicked trail: '+props.trailData.name)} className="no-underline" target="_blank" rel="noopener noreferrer" href={props.trailData.permalink}>
          <h3 className="trails-card--title">{props.trailData.name}</h3>
          <h4 className="trails-card--subtitle">{props.trailData.description}</h4>
        </a>
      </div>
      <div className="trails-card--info">
        {props.trailData.duration ? '~' + props.trailData.duration : ''}
        <br />{props.trailData.score &&  props.trailData.score.trim() !== '' ? '+' + props.trailData.score : ''}</div>
    </div>
  )
};
