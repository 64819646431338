import React, { useState } from 'react';
export default function ResourceCard(props) {
  return (
    <div className="cell small-11 large-5 xlarge-4">
      {/* <div className="material-card--item">
        <div className="material-card--image">
          <img src={props.image} alt={props.title} />
        </div>
        <div className="material-card--body">
          <h3 className="material-card--title">{props.name}</h3>
          <div className="material-card--button">
            <a href={props.permalink} target="_blank">View</a>
          </div>
        </div>
      </div> */}
    <a onClick={props.trackEvent('Clicked resource: '+props.name)} id={props.title} target="_blank" rel="noreferrer noopener" href={props.permalink} className="card card__resources">
    <div className="card__image" style={{ backgroundImage: `url(${props.image})` }}></div>
    <div className="card__inner">
      <h2 className="h3 card__header equal" >{props.name}</h2>
      {props.subtitle !== '' ? <div className="h5 card__subheader">{props.subtitle}</div> : <div/>}
      <div className="button btn-blue">View</div>
    </div>
  </a>
    </div>
  )
}
