import './team_page.scss'
import React, { useEffect } from 'react';
import Equalizer from 'react-equalizer';
import Nav from '../nav.js';
import ReactGA from 'react-ga';
import uuid from 'react-uuid';
import MemberCard from './member_card'
import ResourceCard from './resource_card'
import { useSpring, animated } from 'react-spring';
import butterfly1, { ReactComponent as Butterfly1 } from './images/butterfly1.svg';
import butterfly2, { ReactComponent as Butterfly2 } from './images/butterfly2.svg';
import bird4, { ReactComponent as Bird } from './images/bird5.svg';
import LegalFooter from '../legal_footer/legal_footer';
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
// const trans1 = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
const trans3 = (x, y) => `translate3d(${x / 30 * -1}px,${y / 30}px,0)`;
export default function RewardsPage(props) {
  const [settings, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 50, tension: 550, friction: 140 } }))
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
    };
  }, []);
  var currentTheme = 1;
  var topBg = "images/team_page/contact-bg-top.png";
  var botBg = "images/team_page/contact-bg-bottom.png";
  if (props.settings.theme && props.settings.theme === 2) {
    currentTheme = 2;
    topBg = "images/team_page/contact-bg-top-2.png";
    botBg = "images/team_page/contact-bg-bottom-2.png";
  }

  const sectionTopBgStyle = { backgroundImage: 'url(' + topBg + ')' };
  const sectionBotBgStyle = { backgroundImage: 'url(' + botBg + ')' };
  ReactGA.event({
    category: 'Team',
    action: 'page visited'
  });
  let card1= 
    <MemberCard
    key='team1'
    cardTitle={props.settings.members.member1.name}
    name={props.settings.members.member1.name}
    title={props.settings.members.member1.title}
    photo={props.settings.members.member1.photo}
    linkedin={props.settings.members.member1.contact}
    company={props.settings.members.member1.company}
    trackEvent={props.trackEvent}
  />
  let card2= 
  <MemberCard
  key='team2'
  cardTitle={props.settings.members.member2.name}
  name={props.settings.members.member2.name}
  title={props.settings.members.member2.title}
  photo={props.settings.members.member2.photo}
  linkedin={props.settings.members.member2.contact}
  company={props.settings.members.member2.company}
  trackEvent={props.trackEvent}
/>
  let card3= 
  <MemberCard
  key='team3'
  cardTitle={props.settings.members.member3.name}
  name={props.settings.members.member3.name}
  title={props.settings.members.member3.title}
  photo={props.settings.members.member3.photo}
  linkedin={props.settings.members.member3.contact}
  company={props.settings.members.member3.company}
  trackEvent={props.trackEvent}
/>

  return (
    <>
      <Nav settings={props.settings} />
      <section id="team" className={["material team section full-height text-center section-gap-1 relative",currentTheme === 1 ? "section-bg-gi" : "section-bg-0"].join(' ')}>
        <div className="background-image-top" style={sectionTopBgStyle}></div>
        <div className="background-image-bottom" style={sectionBotBgStyle}></div>
        <div className="container relative" onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
          <div className="cloud4"></div>
          <div className="cloud5"></div>
          <div className="cloud6"></div>
          {/* <animated.div className="bird5" style={{ transform: settings.xy.interpolate(trans1) }}><Bird /></animated.div> */}
          <animated.div className={["butterfly1", currentTheme === 1 ? "" : "hidden"].join(' ')} style={{ transform: settings.xy.interpolate(trans2) }}><Butterfly1 /></animated.div>
          <animated.div className={["butterfly2", currentTheme === 1 ? "" : "hidden"].join(' ')} style={{ transform: settings.xy.interpolate(trans3) }}><Butterfly2 /></animated.div>
          <div className="section-container dark">
                  <h1 className={["section-title", currentTheme === 1 ? "white-text" : ""]}>We’re with you every step of the journey.</h1>
                  {/* <h2 className="section-subtitle">Explore relevant resources to help you succeed on your Trailhead Journey.</h2> */}
                  <div className={["section-subtitle", currentTheme === 1 ? "white-text" : ""]}>
                  Explore your Trailhead support resources.</div>
                  <div className="material-card--container">
                    <div className="grid-container mb-80">
                      <Equalizer property="height" className="grid-x align-center align-top">
                        {Object.keys(props.settings.resources).map((t) => {
                          if (props.settings.resources[t].doc_type === "support")
                            return <ResourceCard
                              key={uuid()}
                              cardTitle={t}
                              name={props.settings.resources[t].name}
                              subtitle={props.settings.resources[t].subtitle}
                              permalink={props.settings.resources[t].permalink}
                              image={props.settings.resources[t].image_url}
                              trackEvent={props.trackEvent}
                            />
                        })}
                      </Equalizer>
                    </div>
                  </div>
                </div>
          <div className="grid-container relative">
            <div className="grid-x">
              <div className="cell">
                <div className="section-container">
                  {/* <h1 className="section-title">We’re with you every step of the journey.</h1> */}
                  {/* <h2 className="section-subtitle">Meet the team rooting for your success as you skill up for the future.</h2> */}
                  {/* <div className="section-description">Explore your Trailhead support resources.</div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <h1 className="text-center">Success Team</h1>
          <div className="team-card--container pb-80 relative">
            <div className="grid-container">
              <div className="grid-x align-center align-stretch">
                {props.settings.members.member1.name !== '' ? card1 : null}
                {props.settings.members.member2.name !== '' ? card2 : null}
                {props.settings.members.member3.name !== '' ? card3 : null}
              </div>
            </div>
          </div> */}
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
      </section>
    </>
  )
};
