import React from 'react';
import Chest from './chest.js';
import uuid from 'react-uuid';
import Loading from '../loading/loading_icon';
import Equalizer from 'react-equalizer';
const chestsBgTop = "images/04_chests/chests-background-top.png";
const greyBgTop = "images/04_chests/chests-background-top-2.png";
const chestsBgBottom = "images/04_chests/chests-background-bottom.png";
const greyBgBottom = "images/04_chests/chests-background-bottom-2.png";
export default function ChestList(props) {
  var sectionBgTopStyle = { backgroundImage: 'url(' + chestsBgTop + ')' };
  var sectionBgBottomStyle = { backgroundImage: 'url(' + chestsBgBottom + ')' };
  var theme = 1;
  if (props.settings && props.settings.theme && props.settings.theme === 2){
    theme = 2;
  }
  if (theme === 2){
    sectionBgTopStyle = { backgroundImage: 'url(' + greyBgTop + ')' };
    sectionBgBottomStyle = { backgroundImage: 'url(' + greyBgBottom + ')' };
  }
  if (props.chestsLoading === true) {
    return <Loading />
  } else {
    return (
      <div className={["chests-card--container", theme === 2 ? 'grey-bg' : 'green-bg' ].join(' ')}>
        <div className={["chests-card--background top", theme === 2 ? 'grey-bg' : 'green-bg' ].join(' ')} style={sectionBgTopStyle}>
          <img src={chestsBgTop} alt="" width="3840" height="740" />
        </div>
        <div className="chests-card--list">
          <div className="grid-container">
            <div className="grid-x align-center">
              {Object.keys(props.chests).map((t) => (
                <Chest
                  key={uuid()}
                  setChest={props.setChest}
                  settings={props.settings}
                  chestData={props.chests[t]}
                  chestIndex={t}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="chests-card--background bottom" style={sectionBgBottomStyle}>
          <img src={chestsBgBottom} alt="" width="3840" height="585" />
        </div>
      </div>
    )
  }
};
