import React from 'react';
import './congrats.scss'
import { Link } from "react-router-dom";
import LegalFooter from '../legal_footer/legal_footer';
export default function Congrats(props) {
  var currentTheme = 1;
  var nameBg = "images/07_congrats/congrats-background-full.png";
  if (props.settings.theme && props.settings.theme === 2) {
    currentTheme = 2;
   nameBg = "images/07_congrats/congrats-background-2.png";
  }
  const sectionBgStyle = { backgroundImage: 'url('+ nameBg +')' };
  // const corpColorBgStyle = { backgroundColor: props.primaryColor };
  const corpColorStyle = { color: props.primaryColor };

  return (
    <section id="congrats-section" className={[props.currentSlide > 3 ? 'congrats section section-bg-2 text-center visible' : 'congrats section section-bg-2 text-center hidden', props.currentSlide === 5 ? 'active' : ''].join(' ')} style={sectionBgStyle}>
      <div className="grid-container">
        <div className="grid-x align-center align-top full-height">
          <div className="cell">
            <div className="section-container section-gap-2">
              <h2 className="section-title">Congrats on crushing new skills, keep going!</h2>
              <h3 className="section-subtitle">You&rsquo;ve completed <em>{props.chestTitle}</em></h3>
              {/* <div className="section-description">Now that&rsquo;s a learning culture worth exploring.</div> */}
              <div className="congrats-button">
                <ul>
                  {/* <li><a className="i1" href="#" onClick={() => props.goToSlide(3)}>Explore more trails</a></li> */}
                  <li><a className="i2" href="https://trailhead.salesforce.com/home" target="_blank">Choose your own Adventure</a></li>
                  <li><Link to="/myrewards" className="i3" href="#">View rewards</Link></li>
                </ul>
              </div>
              <div className = "final-text">
              {/* <h2 className="footer-title">Skill up like a Bosch</h2> */}
              <div className="footer-logo">
                <a href="https://salesforce.com"><img src="/images/salesforce.svg" alt="Salesforce" width="100" height="70" /></a>
              </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <LegalFooter
  consentOpen={props.consentOpen}
  setConsentOpen={props.setConsentOpen}
  />
    </section>
  )
};